$breakpoints: (
  'xs': 0px,
  'sm': 600px,
  'md': 960px,
  'lg': 1280px,
  'xl': 1920px,
);

:root {
  --breakpoint-xs: map-get($breakpoints, 'xs');
  --breakpoint-sm: map-get($breakpoints, 'sm');
  --breakpoint-md: map-get($breakpoints, 'md');
  --breakpoint-lg: map-get($breakpoints, 'lg');
  --breakpoint-xl: map-get($breakpoints, 'xl');
}

@mixin xs {
  @media (max-width: map-get($breakpoints, 'sm')) {
    @content;
  }
}
@mixin sm {
  @media (max-width: map-get($breakpoints, 'md')) {
    @content;
  }
}
@mixin md {
  @media (max-width: map-get($breakpoints, 'lg')) {
    @content;
  }
}
@mixin lg {
  @media (max-width: map-get($breakpoints, 'xl')) {
    @content;
  }
}
@mixin xl {
  @media (min-width: map-get($breakpoints, 'xl')) {
    @content;
  }
}

@mixin breakpoint($bp: 0) {
  @media (max-width: $bp) {
    @content;
  }
}

@mixin mobile {
  @include sm {
    @content;
  }
}

@mixin tablet {
  @include md {
    @content;
  }
}
