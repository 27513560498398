$color-green: #6fb517;
$color-red: #e03c31;
$color-hover-bg: #d5f0ee;

:root {
  --color-hover-bg: #d5f0ee;
  --color-gray-bg: #f3f5f680;
  --color-gray-f8: #f8f8f8;
  --color-gray-bg-icon: #ebebeb;
  --color-gray: #9a9a9a;
  --color-gray-border: #e0e0e0;
  --color-gray-deep: #0000003d;
  --color-middle-gray: #f2f2f2;
  --color-brand: #00b2a9;
  --color-red: #e03c31;
  --color-light-red: #e8756f;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-green: #6fb517;
  --color-light-green: #98c95b;
  --color-green-test: #00b2a9;
  --color-yellow: #eaaa00;
  --color-purple: #8031a7;
}
